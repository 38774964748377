import React from 'react'
import { Helmet } from 'react-helmet';
import HomeLayout from '../components/HomeLayout'
import Four0Four from '../components/Four0Four';

const NotFoundPage = () => (
  <HomeLayout>
    <Helmet title="Page Not Found" />

    <Four0Four />
  </HomeLayout>
);

export default NotFoundPage